import { IntentSummaryData, IntentData, PhraseMarkupData } from '../api/client';
import { getRandomString, uniquify, findDuplicates } from './common';
import { IntentItemType, IntentItem, IntentWithItems } from '../model';

export type IntentTreeNode = {
  id: number;
  nodeId: string;
  name: string;
  enabled: boolean;
  path: string;
  parent: string;
  itemsCount: number;
  children: string[];
  childrenItemsCount: number;
  isGhostNode: boolean;
};

export type IntentDataset = {
  [id: string]: IntentTreeNode;
};

export const FAQBaseIntentName = 'KnowledgeBase';

export function generateFAQPathByTemplateName(name: string) {
  return `/${FAQBaseIntentName}/${name}`;
}

export const getFAQTemplateName = (path: string): string => {
  const parts = path.split('/').filter(Boolean);
  if (parts.length > 1 && parts[0] === FAQBaseIntentName) {
    return parts[1];
  }
  return '';
};

export const mapIntentsToTree = (intents: IntentSummaryData[]): IntentDataset =>
  intents.reduce<IntentDataset>(addIntentToDataset, {});

export const addIntentToDataset = (dataset: IntentDataset, intent: IntentSummaryData): IntentDataset => {
  const node = toTreeNode(intent);

  getAllParentPathsReverse(node.path).forEach((element, i, array) =>
    addChildToNode(dataset, element, array[i - 1] || node.path, node.itemsCount)
  );

  const optionalNode = dataset[node.path];

  if (optionalNode) {
    node.children = optionalNode.children;
    node.childrenItemsCount = optionalNode.childrenItemsCount;
  }

  return addNode(dataset, node);
};

export const getAllParentPathsReverse = (path: string): string[] => {
  const parents = [] as string[];
  for (let i = path.length - 1; i > 0; i--) {
    if (path[i] === '/') parents.push(path.slice(0, i));
  }
  return parents;
};

const addChildToNode = (dataset: IntentDataset, path: string, childPath: string, itemsCount: number): IntentDataset => {
  const optionalNode = dataset[path];

  const node = optionalNode ? optionalNode : createGhostTreeNode(path);

  node.children = uniquify([...node.children, childPath]);
  node.childrenItemsCount = node.childrenItemsCount + itemsCount;

  return addNode(dataset, node);
};

const replaceChildInNode = (dataset: IntentDataset, node: IntentTreeNode, oldChild: string, newChild: string) => {
  const oldChildIndex = node.children.findIndex(child => child === oldChild);
  node.children[oldChildIndex] = newChild;

  return addNode(dataset, node);
};

export const toTreeNode = (intent: IntentSummaryData): IntentTreeNode => ({
  id: Number(intent.id),
  nodeId: intent.path ? intent.path : '',
  path: intent.path ? intent.path : '',
  enabled: Boolean(intent.enabled),
  name: getNameFromPath(intent.path),
  parent: getParentPathFromPath(intent.path),
  itemsCount: intent.itemsCount ? intent.itemsCount : 0,
  children: [],
  childrenItemsCount: 0,
  isGhostNode: false,
});

const createGhostTreeNode = (path: string): IntentTreeNode => ({
  id: NaN,
  nodeId: path,
  path,
  enabled: false,
  name: getNameFromPath(path),
  parent: getParentPathFromPath(path),
  itemsCount: 0,
  children: [],
  childrenItemsCount: 0,
  isGhostNode: true,
});

export const getNameFromPath = (path?: string) => (path ? path.substring(path.lastIndexOf('/') + 1) : '');

export const getParentPathFromPath = (path?: string) => (path ? path.substring(0, path.lastIndexOf('/')) : '');

export const getItemsCountString = (node: IntentTreeNode): string =>
  node.childrenItemsCount ? `${node.itemsCount} / ${node.childrenItemsCount}` : `${node.itemsCount}`;

export const getNewUniquePath = (defaultNewIntentName: string, parentPath: string, tree?: IntentDataset): string => {
  const simplePath = `${parentPath}/${defaultNewIntentName}`;
  if (!tree) {
    return `${simplePath}_${(Math.random() * 10000).toFixed()}`;
  }
  if (!isIntentWithPathExists(simplePath, tree)) return simplePath;

  for (let index = 1; index <= Infinity; index++) {
    const path = `${parentPath}/${defaultNewIntentName} ${index}`;
    if (!isIntentWithPathExists(path, tree)) return path;
  }

  return `${parentPath}/${defaultNewIntentName} ${getRandomString()}`;
};

const isIntentWithPathExists = (path: string, tree: IntentDataset): boolean =>
  Object.values(tree).findIndex(node => node.path === path) > -1;

export const updateIntentPathInTree = (dataset: IntentDataset, oldPath: string, newPath: string) => {
  const newDataset = { ...dataset };

  const parentPath = newDataset[oldPath].parent;
  if (parentPath) replaceChildInNode(newDataset, newDataset[parentPath], oldPath, newPath);

  return updatePathInTree(newDataset, oldPath, newPath);
};

const updatePathInTree = (dataset: IntentDataset, oldPath: string, newPath: string): IntentDataset => {
  const node = dataset[oldPath];

  node.path = newPath;
  node.nodeId = newPath;
  node.name = getNameFromPath(newPath);

  const oldChildren = node.children;
  node.children = node.children.map(child => newPath + child.slice(oldPath.length));

  if (node.parent) node.parent = getParentPathFromPath(newPath);

  delete dataset[oldPath];
  addNode(dataset, node);

  return oldChildren.length
    ? oldChildren.reduce((acc, child, childIndex) => updatePathInTree(acc, child, node.children[childIndex]), dataset)
    : dataset;
};

export const updateIntentItemsCountInTree = (dataset: IntentDataset, path: string, newItemsCount: number) => {
  const newDataset = { ...dataset };
  const node = newDataset[path];
  if (!node) return newDataset;
  addNode(newDataset, { ...node, itemsCount: newItemsCount });

  const diff = newItemsCount - node.itemsCount;
  return updateChildrenItemsCountUpToRoot(newDataset, node.parent, diff);
};

const updateChildrenItemsCountUpToRoot = (dataset: IntentDataset, path: string, diff: number): IntentDataset => {
  if (!path) return dataset;

  const node = dataset[path];
  addNode(dataset, { ...node, childrenItemsCount: node.childrenItemsCount + diff });

  return updateChildrenItemsCountUpToRoot(dataset, node.parent, diff);
};

export const updateIntentEnabledInTree = (tree: IntentDataset, path: string, enabled: boolean) => {
  const updatedNodes = {} as IntentDataset;
  updateEnabled(tree, path, enabled, updatedNodes);
  return {
    ...tree,
    ...updatedNodes,
  };
};

const updateEnabled = (dataset: IntentDataset, path: string, enabled: boolean, updatedNodes: IntentDataset) => {
  updatedNodes[path] = { ...dataset[path], enabled };
  dataset[path].children.forEach(childPath => updateEnabled(dataset, childPath, enabled, updatedNodes));
};

export const mergeNewIntentIntoTree = (intent: IntentData, dataset: Readonly<IntentDataset>): IntentDataset => {
  const newDataset = { ...dataset };
  const node = toTreeNode(toSummaryData(intent));
  addNode(newDataset, node);
  if (!node.parent) return newDataset;

  const parent = dataset[node.parent];

  //TODO: Add logic to create ghost nodes
  if (!parent) return newDataset;

  return addChildToNode(newDataset, node.parent, node.path, 0);
};

export const replaceGhostNodeWithRealNode = (
  intent: IntentData,
  path: string,
  dataset: Readonly<IntentDataset>
): IntentDataset => {
  const newDataset = { ...dataset };
  const node = toTreeNode(toSummaryData(intent));
  const ghostNode = dataset[path];

  node.children = ghostNode.children;
  node.childrenItemsCount = ghostNode.childrenItemsCount;

  return addNode(newDataset, node);
};

export const removeIntentsFromTree = (removedIds: number[], dataset: Readonly<IntentDataset>): IntentDataset =>
  Object.values(dataset)
    .filter(({ id }) => !removedIds.includes(id))
    .map(node =>
      node.children
        ? {
            ...node,
            children: node.children.filter(
              childPath => dataset[childPath] && !removedIds.includes(dataset[childPath].id)
            ),
          }
        : node
    )
    .reduce(addNode, {});

const addNode = (dataset: IntentDataset, node: IntentTreeNode) => Object.assign(dataset, { [node.nodeId]: node });

export const getRealChildrenIds = (dataset: Readonly<IntentDataset>, selectedIntentPaths: string[]) => {
  const realChildrenIds: number[] = [];
  selectedIntentPaths.forEach(path => updateRealChildrenIds(dataset, path, realChildrenIds));
  return uniquify(realChildrenIds);
};

const updateRealChildrenIds = (dataset: Readonly<IntentDataset>, path: string, realChildrenIds: number[]) => {
  if (!dataset[path].isGhostNode) {
    realChildrenIds.push(dataset[path].id);
  }
  dataset[path].children.forEach(childPath => updateRealChildrenIds(dataset, childPath, realChildrenIds));
};

export const getDuplicateSlotNames = (slots: IntentData['slots']) => {
  const slotsNames: string[] = slots ? slots.map(slot => String(slot.name)) : [];
  return findDuplicates(slotsNames);
};

export const getEmptySlotNames = (slots: IntentData['slots']) =>
  slots
    ? slots.filter(slot => !slot.name || !/\S/.test(String(slot.name)) || !slot.entity).map(slot => String(slot.name))
    : [];

export const toIntentItem = (text: string, type: IntentItemType): IntentItem => ({
  type: type,
  text: text,
});

export const phraseToIntentItem = (phrase: PhraseMarkupData): IntentItem => {
  return toIntentItem(phrase.text || '', IntentItemType.phrase);
};

export const patternToIntentItem = (pattern: string): IntentItem => {
  return toIntentItem(pattern || '', IntentItemType.pattern);
};

const intentItemToPhrase = (item: IntentItem) => ({
  text: item.text,
});

const intentItemToPattern = (item: IntentItem) => item.text;

const splitItemsByType = (items: IntentItem[]) =>
  items.reduce(
    (acc, item) =>
      Object.assign(acc, {
        [item.type]: [
          ...acc[item.type],
          item.type === IntentItemType.phrase ? intentItemToPhrase(item) : intentItemToPattern(item),
        ],
      }),
    { [IntentItemType.phrase]: [] as PhraseMarkupData[], [IntentItemType.pattern]: [] as string[] }
  );

export const updateItems = (intent: IntentData, items: IntentItem[]): IntentData => {
  const phrasesAndPatterns = splitItemsByType(items);
  return {
    ...intent,
    phrases: phrasesAndPatterns[IntentItemType.phrase],
    patterns: phrasesAndPatterns[IntentItemType.pattern],
  };
};

export const convertToIntentWithItems = (intent: IntentData): IntentWithItems => {
  const denulledIntent = {
    ...intent,
    phrases: intent?.phrases ? intent?.phrases : [],
    patterns: intent?.patterns ? intent?.patterns : [],
  };
  return {
    intent: denulledIntent,
    items: [...denulledIntent.phrases!.map(phraseToIntentItem), ...denulledIntent.patterns!.map(patternToIntentItem)],
  };
};

const toSummaryData = (intent: IntentData): IntentSummaryData => ({
  id: Number(intent.id),
  path: String(intent.path),
  enabled: Boolean(intent.enabled),
  itemsCount: intent.phrases ? intent.phrases.length : 0,
});

export const isValidPath = (path?: string) => path && !path.endsWith('/') && !path.match(/\/[\s]+$/);
