export const chatwidgeteditorLocalization = {
  'ChatWidgetEditor headline show label': {
    eng: 'Name',
    ru: 'Название',
  },
  'ChatWidgetEditor headline placeholder': {
    eng: 'Online advisor',
    ru: 'Онлайн-консультант',
  },
  'ChatWidgetEditor avatar show label': {
    eng: 'Avatar',
    ru: 'Аватар',
  },
  'ChatWidgetEditor names show label': {
    eng: 'Names',
    ru: 'Имена',
  },
  'ChatWidgetEditor user name placeholder': {
    eng: 'User',
    ru: 'Пользователь',
  },
  'ChatWidgetEditor bot name placeholder': {
    eng: 'Bot',
    ru: 'Бот',
  },
  'ChatWidgetEditor operator name placeholder': {
    eng: 'Agent',
    ru: 'Оператор',
  },
  'ChatWidgetEditor avatars show label': {
    eng: 'Avatars',
    ru: 'Аватарки',
  },
  'ChatWidgetEditor linkPreview show label': {
    eng: 'Link preview',
    ru: 'Предварительный просмотр ссылок',
  },
  'ChatWidgetEditor Show names label': {
    eng: 'Names',
    ru: 'Имена',
  },
  'ChatWidgetEditor:Show:Animation': {
    eng: 'Animation when waiting for bot response',
    ru: 'Анимация при ожидании ответа бота',
  },
  'ChatWidgetEditor send button label': {
    eng: 'Users send messages',
    ru: 'Пользователи отправляют сообщения',
  },
  'ChatWidgetEditor use button label': {
    eng: 'Disable input when using buttons',
    ru: 'Блокировать ввод при использовании кнопок',
  },
  'ChatWidgetEditor send button and enter': {
    eng: 'Enter pressed or button clicked',
    ru: 'по Enter или кнопке',
  },
  'ChatWidgetEditor:SendConfig:SendOnlyEnter': {
    eng: 'Send by Enter button only',
    ru: 'Отправлять только по кнопке Enter',
  },
  'ChatWidgetEditor:SendConfig:VoiceMessages': {
    eng: 'Allow voice messages',
    ru: 'Разрешить голосовые сообщения',
  },
  'ChatWidgetEditor send enter': {
    eng: 'Enter pressed',
    ru: 'по Enter',
  },
  'ChatWidgetEditor user form input placehoder': {
    eng: 'Input field tip',
    ru: 'Подсказка в поле ввода',
  },
  'ChatWidgetEditor user form input info': {
    eng: 'Leave the field empty if the tip is not required',
    ru: 'Оставьте поле пустым, если подсказка не нужна',
  },
  'ChatWidgetEditor position desktop label': {
    eng: 'On a desktop',
    ru: 'На десктопе',
  },
  'ChatWidgetEditor position right placeholder': {
    eng: 'Right indent, px',
    ru: 'Отступ справа, px',
  },
  'ChatWidgetEditor position bottom placeholder': {
    eng: 'Bottom indent, px',
    ru: 'Отступ снизу, px',
  },
  'ChatWidgetEditor position mobile label': {
    eng: 'On a mobile',
    ru: 'На мобильном',
  },
  'ChatWidgetEditor position mobile left': {
    eng: 'Left',
    ru: 'Слева',
  },
  'ChatWidgetEditor position mobile right': {
    eng: 'Right',
    ru: 'Справа',
  },
  'ChatWidgetEditor involving float message show label': {
    eng: 'Popup message',
    ru: 'Всплывающее сообщение',
  },
  'ChatWidgetEditor involving float message placeholder': {
    eng: 'Message text',
    ru: 'Текст сообщения',
  },
  'ChatWidgetEditor involving float mobile message placeholder': {
    eng: 'Message text',
    ru: 'Текст сообщения для мобильного телефона',
  },
  'ChatWidgetEditor involving delay placeholder': {
    eng: 'Show after, sec',
    ru: 'Показывать спустя, сек',
  },
  'ChatWidgetEditor involving events show label': {
    eng: 'Open the chat window when',
    ru: 'Открыть окно чата по наступлению условия',
  },
  'ChatWidgetEditor involving event page_time': {
    eng: 'Time at the page',
    ru: 'Время на странице',
  },
  'ChatWidgetEditor involving event website_time': {
    eng: 'Time at the website',
    ru: 'Время на сайте',
  },
  'ChatWidgetEditor involving event longer': {
    eng: 'greater than',
    ru: 'больше',
  },
  'ChatWidgetEditor involving event seconds': {
    eng: 'sec',
    ru: 'сек',
  },
  'ChatWidgetEditor involving add event button label': {
    eng: 'Add condition',
    ru: 'Добавить условие',
  },
  'ChatWidgetEditor involving greeting show label': {
    eng: 'Welcome message in the chat window',
    ru: 'Приветствие в окне чата',
  },
  'ChatWidgetEditor involving greeting info': {
    eng: 'Send a greeting to the user. Add some menu buttons so the user can choose a conversation topic. Set up the welcome message and the button titles. The session will start after the user has sent their first message. ',
    ru: 'Поприветствуйте пользователя. Добавьте кнопки меню, чтобы пользователь мог выбрать тему разговора. Настройте приветствие и названия кнопок. Сессия начнется после ответа пользователя. ',
  },
  'ChatWidgetEditor involving greeting text placeholder': {
    eng: 'Enter the welcome message text',
    ru: 'Введите текст приветствия',
  },
  'ChatWidgetEditor involving greeting delay placehoder': {
    eng: 'Show after, sec',
    ru: 'Показывать спустя, сек',
  },
  'ChatWidgetEditor main setting users percentage placeholder': {
    eng: 'Audience (%)',
    ru: 'Аудитория (%)',
  },
  'ChatWidgetEditor main setting widgetName label': {
    eng: 'Name',
    ru: 'Название',
  },
  'ChatWidgetEditor main setting token label': {
    eng: 'Token',
    ru: 'Токен',
  },
  'ChatWidgetEditor main setting webhook label': {
    eng: 'Authentication webhook',
    ru: 'Вебхук для аутентификации',
  },
  'ChatWidgetEditor main setting branch label': {
    eng: 'Branch',
    ru: 'Ветка',
    cn: '來源',
  },
  'ChatWidgetEditor main setting operator label': {
    eng: 'Agent',
    ru: 'Оператор',
  },
  'ChatWidgetEditor main setting operator placeholder': {
    eng: 'Select a customer engagement platform',
    ru: 'Выберите операторский канал',
  },
  'ChatWidgetEditor main setting file upload label': {
    eng: 'Allow sending files',
    ru: 'Разрешить отправку файлов',
  },
  'ChatWidgetEditor main setting copy project label': {
    eng: 'Copy project settings',
    ru: 'Копировать настройки проекта',
  },
  'ChatWidgetEditor main setting copy project placeholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'ChatWidgetEditor main setting rollout label': {
    eng: 'Deploy',
    ru: 'Публикация',
  },
  'ChatWidgetEditor bot ready code label': {
    eng: 'Widget code',
    ru: 'Код виджета',
  },
  'ChatWidgetEditor bot ready code copy code': {
    eng: 'Copy',
    ru: 'Скопировать',
  },
  'ChatWidgetEditor bot ready code description': {
    eng: 'Insert this code anywhere between the <head></head> tags on all pages of your website where you want to have your bot widget deployed.',
    ru: 'Вставьте этот код в любое место между тегами <head></head> на всех страницах вашего сайта, где вы хотите разместить виджет с вашим ботом.',
  },
  'ChatWidgetEditor font label': {
    eng: 'Font',
    ru: 'Шрифт',
  },
  'ChatWidgetEditor avatar size label': {
    eng: 'Avatar size',
    ru: 'Размер аватаров',
  },
  'ChatWidgetEditor colors label': {
    eng: 'Theme color',
    ru: 'Цвет темы',
  },
  'ChatWidgetEditor headline colors label': {
    eng: 'Title',
    ru: 'Заголовок',
  },
  'ChatWidgetEditor headline color': {
    eng: 'Background',
    ru: 'Фон',
  },
  'ChatWidgetEditor headline color text label': {
    eng: 'Text color',
    ru: 'Цвет текста',
  },
  'ChatWidgetEditor headline color text dark': {
    eng: 'Dark',
    ru: 'Темный',
  },
  'ChatWidgetEditor headline color text light': {
    eng: 'Light',
    ru: 'Светлый',
  },
  'ChatWidgetEditor chat opacity': {
    eng: 'Transparency',
    ru: 'Прозрачность',
  },
  'ChatWidgetEditor message colors label': {
    eng: 'User messages',
    ru: 'Сообщения пользователя',
  },
  'ChatWidgetEditor form label': {
    eng: 'Menu and Message sending',
    ru: 'Меню и Отправка сообщений',
  },
  'ChatWidgetEditor window colors label': {
    eng: 'Chat window',
    ru: 'Окно чата',
  },
  'ChatWidgetEditor menu color': {
    eng: 'Menu',
    ru: 'Меню',
  },
  'ChatWidgetEditor send button color': {
    eng: 'Message sending',
    ru: 'Отправка сообщений',
  },
  'ChatWidgetEditor bot message colors label': {
    eng: 'Bot and agent messages',
    ru: 'Сообщения бота и оператора',
  },
  'ChatWidgetEditor form text color': {
    eng: 'Text',
    ru: 'Текст',
  },
  'ChatWidgetEditor form buttons color': {
    eng: 'Buttons',
    ru: 'Кнопки',
  },
  'ChatWidgetEditor setting copy project apply': {
    eng: 'Apply',
    ru: 'Применить',
  },
  'ChatWidgetEditor custom theme title': {
    eng: 'Change any color to create your own theme',
    ru: 'Поменяйте любой цвет, чтобы создать свою тему',
  },
  'ChatWidgetEditor Color Themes label': {
    eng: 'Visual design',
    ru: 'Оформление',
  },
  'ChatWidgetEditor Reset Settings': {
    eng: 'Reset settings',
    ru: 'Сброс настроек',
  },
  'ChatWidgetEditor Chat Header label': {
    eng: 'Chat title',
    ru: 'Заголовок чата',
  },
  'ChatWidgetEditor Chat Name label': {
    eng: 'Chat window',
    ru: 'Окно чата',
  },
  'ChatWidgetEditor Chat Send label': {
    eng: 'Message sending',
    ru: 'Отправка сообщений',
  },
  'ChatWidgetEditor Chat Position label': {
    eng: 'Location',
    ru: 'Расположение',
  },
  'ChatWidgetEditor Chat Chat Involving label': {
    eng: 'Involvement in conversation',
    ru: 'Вовлечение в диалог',
  },
  'ChatWidgetEditor Chat Additional Settings label': {
    eng: 'General settings',
    ru: 'Общие настройки',
  },
  'ChatWidgetEditor Chat Set up label': {
    eng: 'Installation',
    ru: 'Установка',
  },
  'ChatWidgetEditor blinkOnNewMessage': {
    eng: 'Display new message notification',
    ru: 'Показывать новое сообщение миганием вкладки',
  },
  'ChatWidgetEditor buttonsSamePlace': {
    eng: 'Display inline buttons above text input field',
    ru: 'Показывать кнопки только над формой ввода',
  },
  'ChatWidgetEditor main setting copy setting placeholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'ChatWidgetEditor show menu buttons': {
    eng: 'Show menu buttons',
    ru: 'Показать кнопки меню',
  },
  'ChatWidgetEditor delete link': {
    eng: 'Delete the link',
    ru: 'Удалить ссылку',
  },
  'ChatWidgetEditor add button': {
    eng: 'Add a new button',
    ru: 'Добавить кнопку',
  },
  'ChatWidgetEditor urlEditor Link': {
    eng: 'Link',
    ru: 'Ссылка',
  },
  'ChatWidgetEditor sound notifications': {
    eng: 'Sound notifications',
    ru: 'Звуковые уведомления',
  },
  'ChatWidgetEditor:Show:CloseButton': {
    eng: 'Widget close button',
    ru: 'Кнопка закрытия виджета',
  },
  'ChatWidgetEditor:Percentage:HelperText': {
    eng: 'The percentage of clients who will see the widget on the website',
    ru: 'Процент пользователей, которые увидят виджет на сайте',
  },
};
